import { unref, watchEffect, type MaybeRef } from "vue";
import { Api } from "@/composables/api-models";
import { useCustomerIdRef } from "@/composables/api/auth";
import awsmobile from "@/aws-exports";
import { useAuth } from "@/composables/auth";

const {cachedIdToken} = useAuth();

const httpClient = new Api<{ useAuth?: boolean }>({
	baseApiParams: { secure: true },
	securityWorker: async params => {
		if (!params?.useAuth) return {};
		
		const customerId = useCustomerIdRef().value;

		const headers: Record<string, string> = {
			Authorization: `Bearer ${cachedIdToken.value}`,
		};

		if (customerId){
			headers["ProjectId"] = customerId;
		}
			 
		return { headers };
	},
});


export const initClient = (
	isLocal: boolean,
	defaultUrl: string,
) => {
	httpClient.baseUrl = isLocal
		? awsmobile.aws_cloud_logic_custom[0].endpoint
		: defaultUrl;
	httpClient.setSecurityData({
		useAuth: !isLocal,
	});
};

export const initClientReactive = (
	baseUrl: MaybeRef<string>,
	useAuth: MaybeRef<boolean>,
) => {
	watchEffect(() => {
		httpClient.baseUrl = unref(baseUrl);
		httpClient.setSecurityData({
			useAuth: unref(useAuth),
		});

	});
};

export const useHttp = (): Api<unknown> => httpClient as Api<unknown>;