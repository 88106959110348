<script setup lang="ts">
	import ClockSpinnerIcon from "@/icons/ClockSpinnerIcon.vue";
	import type { Group } from "@/composables/umc-api";
	import { UserLevel, GroupType } from "@/composables/umc-api";
	import { useValidation } from "@jca/libs/forms";
	import { DialogLayout, TextInput } from "@jca/libs/ui";
	import { onBeforeUnmount, onMounted, reactive, ref, watchEffect } from "vue";
	import { useCustomerId } from "@/composables/api/auth";
	import { z } from "zod";
	import { capitalizeFirstLetter } from "/src/composables/utils";
	import Dropdown from "@/components/Dropdown.vue";
	import { Option} from "@/components/Dropdown.vue";
	const props = defineProps<{
		loading: boolean;
		userType: string;
		error?: string | null;
		userOptions: Option[];
		users: { Username: string }[];
	}>();
	const page = ref(1);

	const emit = defineEmits<{
		close: [{ email: string; selectedGroup: Group; projectId: string }?];
	}>();

	const userSchema = z.object({
		email: z.string().email("Invalid email address").regex(/@(ptxtrimble|ptxag)\.com$/, "Email must be a ptxtrimble or ptxag address"),
	});

	const selectedGroup = ref<string>("");

	const user = reactive({
		email: "",
		selectedGroup: GroupType.PortalAdmin as Group,
		projectId: useCustomerId(),
	});

	const validation = useValidation(user, userSchema);

	async function save() {
		if (validation.validate()) emit("close", user);
	}

	// Track the state of the dropdown and search box
	const isDropdownOpen = ref(false);
	const isTyping = ref(false);
	// Track the state of the dropdown (whether it is open or not)
	// Function to toggle dropdown open/close state
	const toggleDropdown = () => {
		isDropdownOpen.value = !isDropdownOpen.value;
	};

	// Function to handle typing in the search box
	const handleSearchTyping = () => {
		isTyping.value = true;
	};

	const handleSelection = (selectedUser: Option) => {
		user.email = selectedUser.label;
	};

	watchEffect(() => {
		if (props.userType !== UserLevel.Subscriber) {
			selectedGroup.value = GroupType.PortalAdmin;
		} else {
			selectedGroup.value = GroupType.ProjectOwner;
		}
		user.selectedGroup = selectedGroup.value as Group;
	});
</script>

<template>
	<DialogLayout class="w-[20rem] bg-netural-orange">
		<template #title>
			<span class="font-bold text-dark-green"
				>Add
				{{
					capitalizeFirstLetter(props.userType).substring(
						0,
						props.userType.length - 1,
					)
				}}</span
			>
		</template>

		<TextInput
			v-model="user.email"
			:label="'Email Address'"
			placeholder=" "
			:error="validation.errors.email"
			@blur="validation.touch.email"
			class="mb-2 text-dark-green"
			v-if="props.userType == UserLevel.User"
		/>

		<Dropdown
			:options="props.userOptions"
			placeholder="Select a User"
			valueField="id"
			displayField="id"
			@click="toggleDropdown"
			@search="handleSearchTyping"
			@update:modelValue="handleSelection"
			v-if="props.userType == UserLevel.Subscriber"
		/>
		<div
				v-if="validation.errors.email && props.userType != UserLevel.User"
				class="error-text ml-4 absolute top-[6.5rem]"
			>
				Please select a user
			</div>

		<div
			v-if="error"
			class="text-red"
			style="bottom: -1rem"
		>
			{{ error }}
		</div>
		<template #actions>
			<button
				class="button bg-light-orange"
				:disabled="props.loading"
				@click="emit('close')"
			>
				Cancel
			</button>
			<button
				class="button bg-bright-orange"
				:class="{ 'button-error': !validation.valid.value }"
				:disabled="props.loading"
				@click="save"
			>
				<ClockSpinnerIcon v-if="loading" />
				<span>Save</span>
			</button>
		</template>
	</DialogLayout>
</template>
