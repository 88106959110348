<script setup lang="ts">
	import { emptyGUID } from "@jca/libs/api";
	import type { Project } from "@/composables/api-models";
	import { UserLevel } from "@/composables/umc-api";
	import DataTable from "primevue/datatable";
	import type { DataTableFilterMetaData } from "primevue/datatable";
	import Column from "primevue/column";
	import { ref } from "vue";
	import Loading from "@/components/Loading.vue";
	import Search from "@/components/Search.vue";

	const props = defineProps<{
		loading: boolean;
		projects: Project[];
		allowCreate: boolean;
	}>();

	const emit = defineEmits<{
		add: [];
		delete: [projectId: string];
		update: [project: Project];
	}>();

	// this holds the filter values from primevue table
	const filters = ref<{ global: DataTableFilterMetaData }>({
		global: {
			value: null,
			matchMode: "contains",
		},
	});

	// we don't want to show the empty project for admin
	const filterProjects = (projects: Project[]) => {
		return projects.filter(project => project.ProjectId !== emptyGUID);
	};
</script>

<template>
	<div class="box-border flex min-h-0 flex-col gap-4 p-4">
		<div
			class="customizedPrimeVueTable flex grid grid-cols-4 flex-col gap-2 gap-4 overflow-y-auto"
		>
			<DataTable
				tableStyle="min-width: 100%"
				paginator
				:rows="10"
				:filters="filters"
				:value="filterProjects(projects)"
				:loading="loading"
				:globalFilterFields="[
					'ID',
					'Name',
					'Description',
					'ZulipStream',
					'ZulipTopic',
				]"
				class="col-span-4"
			>
				<template #header>
					<div class="mb-5 flex justify-between">
						<Search :filters-prop="filters" />
						<!-- Search component -->

						<button
							class="mb-2 mr-2 flex items-center rounded bg-bright-orange px-4 py-2 font-bold text-dark-orange md:mb-0"
							@click="$emit('add')"
							v-if="props.allowCreate"
						>
							<span> Create New Project </span>
						</button>
					</div>
				</template>
				<template>
					<Column
						field="Name"
						header="Project"
						sortable
					/>

					<Column
						field="Description"
						header="Description"
						v-if="allowCreate"
						sortable
					/>

					<Column
						field="ProjectId"
						header="ID "
						sortable
						v-if="allowCreate"
					/>

					<Column
						field="Zulip"
						header="Zulip"
						v-if="allowCreate"
						sortable
					>
						<template #body="{ data }">
							{{
								data.ZulipStream
									? data.ZulipStream + "/" + data.ZulipTopic
									: "N/A"
							}}
						</template>
					</Column>

					<Column
						field="ProjectId"
						header="Actions"
					>
						<template #body="{ data }">
							<div class="flex h-24 w-full items-center justify-center">
								<button
									:title="'Enable Zulip Notifications'"
									class="button icon-button button-secondary"
									@click="emit('update', data)"
									v-if="allowCreate"
								>
									<i class="material-icons">edit</i>
								</button>

								<button
									:title="'Machines'"
									class="button icon-button button-secondary"
								>
									<!-- RouterLink to navigate to the route with a route param (projectId) -->
									<RouterLink
										:to="{
											name: 'machines',
											params: {
												projectId: data.ProjectId ? data.ProjectId : null,
											},
										}"
									>
										<i class="material-icons">agriculture</i>
									</RouterLink>
								</button>

								<button
									:title="'Users'"
									class="button icon-button button-secondary"
								>
									<!-- RouterLink to navigate to the route with a route param (projectId) -->
									<RouterLink
										:to="{
											name: 'users',
											params: {
												projectId: data.ProjectId ? data.ProjectId : null,
												userType: UserLevel.Subscriber,
											},
										}"
									>
										<i class="material-icons">group</i>
									</RouterLink>
								</button>
							</div>
						</template>
					</Column>
				</template>
				<template #empty>No project found.</template>
				<template #loading>
					<Loading typeOfData="Projects" />
				</template>
			</DataTable>
		</div>
	</div>
</template>
