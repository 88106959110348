<script setup lang="ts">
	import ClockSpinnerIcon from "@/icons/ClockSpinnerIcon.vue";
	import { DialogLayout } from "@jca/libs/ui";
	import { computed, reactive, ref, watchEffect } from "vue";
	import { Project } from "@/composables/api-models";
	import { z } from "zod";
	import { useValidation } from "@jca/libs/forms";
	import { Checkbox, TextInput } from "@jca/libs/ui";
	import BotDescription from "@/components/UMC/BotDescription.vue";

	const props = defineProps<{
		loading: boolean;
		error?: string;
		project: Project;
	}>();
   
    const isLoading = computed(() => props.loading);

    const streamErrorMsg = "Stream is required";
    const topicErrorMsg = "Topic is required";


	const result = reactive({
		projectId:  props.project.ProjectId ?? "",
		zulipStream:  props.project.ZulipStream ?? "",
		zulipTopic: props.project.ZulipTopic ?? "",
	});

    const emit = defineEmits<{
        close: [
			{
				projectId: string;
                zulipStream: string;
                zulipTopic: string;
			}?,
		];
    }>();


    const isZulipChecked = ref(
        !!props.project?.ZulipStream && !!props.project?.ZulipTopic
    );

    const projectSchema = {
        projectId: z.string().min(1, { message: "Project is required" }),
        zulipStream: z.string().min(1, { message: streamErrorMsg }),
        zulipTopic: z.string().min(1, { message: topicErrorMsg }),
    };

    const schema = computed(() => {
        if (!isZulipChecked.value) {
            projectSchema.zulipStream = z.string();
            projectSchema.zulipTopic = z.string();
        }
        return z.object(projectSchema);
    });

    const validation = useValidation(result, schema.value);

    async function save() {
		if (validation.validate()) {

			emit("close", {
				projectId: result.projectId,
                zulipStream: result.zulipStream,
                zulipTopic: result.zulipTopic,
			});
		}
	}

    watchEffect(() => {
        if (!isZulipChecked.value) {
            result.zulipStream = "";
            result.zulipTopic = "";
            projectSchema.zulipStream = z.string();
            projectSchema.zulipTopic = z.string();
        }else{
            projectSchema.zulipStream = z.string().min(1, { message: streamErrorMsg });
            projectSchema.zulipTopic = z.string().min(1, { message: topicErrorMsg });
        }
    });
</script>

<template>
	<DialogLayout class="w-[20rem] bg-netural-orange">
		<template #title>
			<span class="font-bold text-dark-green">Edit Project</span>
		</template>

        <div class="flex flex-col space-y-2 pl-4">
            <label class="text-dark-green">Project Name: {{ props.project.Name }}</label>
            <label class="text-dark-green">Project Description: {{ props.project.Description }}</label>
        </div>
        
   
		<div class="uiCheckbox mx-auto w-full max-w-sm pl-4">
			<Checkbox
				v-model="isZulipChecked"
				label="Enable Zulip Notification"
				class="mt-1"
			/>

			<BotDescription v-if="isZulipChecked" />
		</div>
		<TextInput
			v-model="result.zulipStream"
			label="Zulip Stream"
			placeholder=""
			class="w-full"
			v-if="isZulipChecked"
			:error="validation.errors.zulipStream"
		/>

		<TextInput
			v-model="result.zulipTopic"
			label="Zulip Topic"
			placeholder=""
			class="w-full"
			v-if="isZulipChecked"
			:error="validation.errors.zulipTopic"
		/>

		<template #actions>
			<button
				class="button bg-light-orange"
				:disabled="props.loading"
				@click="emit('close')"
			>
				Cancel
			</button>
			<button
				class="button bg-bright-orange"
				:disabled="isLoading"
				@click="save"
			>
				<ClockSpinnerIcon v-if="isLoading" />
				<span>Save</span>
			</button>
		</template>
	</DialogLayout>
</template>
